import React from 'react';

type Props = {
  separator?: React.ReactNode;
  containerClassName: string;
  itemClassName: string;
  separatorClassName?: string;
  /**
   * define HTML structure for list
   * native - ul/li
   * non-native - div/span
   */
  native?: boolean;
};

const List: React.FC<Props> = ({
  children,
  containerClassName,
  itemClassName,
  separatorClassName = itemClassName,
  separator = null,
  native = true,
}) => {
  const childrenArray = React.Children.toArray(children);

  if (childrenArray.length === 0) {
    return null;
  }

  return native ? (
    <ul className={containerClassName}>
      {childrenArray.map((child, index) => {
        const key =
          typeof child === 'object' && 'key' in child ? child.key : index;

        return (
          <>
            <li key={key} className={itemClassName}>
              {child}
            </li>
            {index < childrenArray.length - 1 && separator ? (
              <li key={`${key}-separator`} className={separatorClassName}>
                {separator}
              </li>
            ) : null}
          </>
        );
      })}
    </ul>
  ) : (
    <div className={containerClassName}>
      {childrenArray.map((child, index) => {
        const key =
          typeof child === 'object' && 'key' in child ? child.key : index;
        return (
          <>
            <span key={key} className={itemClassName}>
              {child}
            </span>
            {index < childrenArray.length - 1 && separator ? (
              <span key={`${key}-separator`} className={separatorClassName}>
                {separator}
              </span>
            ) : null}
          </>
        );
      })}
    </div>
  );
};

export default List;
